import {
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    DrawerProps,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { IconX } from '@tabler/icons-react';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useModal } from 'mui-modal-provider';
import { Link, useSearchParams } from 'react-router-dom';
import {
    EVoiTransaction,
    eVoITransactionQuery,
    eVoITransactionsQuery,
} from '../../api/eVoiTransaction';
import DateTimeString from '../../components/DateTimeString';
import IDVerseBadge from '../../components/IDVerseBadge';
import Placeholder from '../../components/Placeholder';
import QueryProgress from '../../components/QueryProgress';
import TableSortLabel from '../../components/TableSortLabel';
import ReadonlyField from '../../components/fields/ReadonlyField';
import InitiateVoIDialog from '../../components/verificationOfIdentity/InitiateVoIDialog';
import VoIResults from '../../components/verificationOfIdentity/VoIResults';
import VoiStatusChip from '../../components/verificationOfIdentity/VoIStatusChip';
import useSort from '../../hooks/useSort';
import ToolPageLayout from './ToolPageLayout';

function VerificationOfIdentityTool() {
    const { showModal } = useModal();
    const [searchParams, setSearchParams] = useSearchParams();
    const open_e_voi = searchParams.get('e_voi');

    const defaultSort = '-created_at';
    const { sort, sortColumn, sortDirection, sortLink } = useSort(defaultSort);
    const isActive = (column: string) => sortColumn === column;

    const eVoITransactionParams = {
        ordering: sort,
        standalone_tool: true,
    };

    const query = useInfiniteQuery({
        ...eVoITransactionsQuery(eVoITransactionParams),
        placeholderData: (previousData, _) => previousData,
    });

    const results = (query.data?.pages.flatMap((page) => page.results) || []) as EVoiTransaction[];

    const StartTransactionButton = () => (
        <Button
            variant="contained"
            onClick={() =>
                showModal(InitiateVoIDialog, {
                    onSubmit: () => query.refetch(),
                })
            }
        >
            New verification
        </Button>
    );

    return (
        <ToolPageLayout
            title="Verification of Identity"
            description="Request an electronic Verification of Identity"
            right={
                results.length ? (
                    <Stack direction="row" alignItems="center" gap={2}>
                        {query.isRefetching ? <CircularProgress size={20} /> : null}
                        <StartTransactionButton />{' '}
                    </Stack>
                ) : null
            }
        >
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    overflow: 'hidden',
                    borderRadius: 5,
                    p: 1,
                }}
            >
                <QueryProgress query={query}>
                    <TableContainer
                        sx={{
                            display: 'flex',
                            overflow: 'auto',
                            flexGrow: 1,
                            flexDirection: 'column',
                        }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <TableSortLabel
                                            label="Given names"
                                            sortLink={sortLink('input_given_names')}
                                            sortDirection={sortDirection}
                                            isActive={isActive('input_given_names')}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            label="Family name"
                                            sortLink={sortLink('input_family_name')}
                                            sortDirection={sortDirection}
                                            isActive={isActive('input_family_name')}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            label="Initiated"
                                            sortLink={sortLink('created_at')}
                                            sortDirection={sortDirection}
                                            isActive={isActive('created_at')}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            label="Initiated by"
                                            sortLink={sortLink('triggered_by__name')}
                                            sortDirection={sortDirection}
                                            isActive={isActive('triggered_by__name')}
                                        />
                                    </TableCell>

                                    <TableCell>Status</TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            label="Report expires"
                                            sortLink={sortLink('archives_at')}
                                            sortDirection={sortDirection}
                                            isActive={isActive('archives_at')}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {results.map(
                                    ({
                                        id,
                                        input_family_name,
                                        input_given_names,
                                        status,
                                        created_at,
                                        triggered_by,
                                        archives_at,
                                    }) => (
                                        <TableRow
                                            component={Link}
                                            key={id}
                                            hover
                                            to={`?e_voi=${id}`}
                                            sx={{
                                                textDecoration: 'none',
                                            }}
                                        >
                                            <TableCell>{input_given_names}</TableCell>
                                            <TableCell>{input_family_name}</TableCell>
                                            <TableCell>{DateTimeString(created_at)}</TableCell>
                                            <TableCell>{triggered_by.name}</TableCell>
                                            <TableCell>
                                                <VoiStatusChip
                                                    status={status}
                                                    sx={{ m: 0, p: 0 }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                {archives_at ? DateTimeString(archives_at) : 'N/A'}
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                            </TableBody>
                        </Table>
                        {!results.length ? (
                            <Placeholder
                                title="No verifications yet"
                                description={<StartTransactionButton />}
                            />
                        ) : null}
                    </TableContainer>
                </QueryProgress>
            </Paper>

            <EVoIDrawer
                open={!!open_e_voi}
                e_voi={open_e_voi || ''}
                onClose={() => setSearchParams('')}
            />
        </ToolPageLayout>
    );
}

interface EVoIDrawerProps extends Omit<DrawerProps, 'onClose'> {
    e_voi: string;
    onClose: () => void;
}

function EVoIDrawer({ e_voi, onClose, ...drawerProps }: EVoIDrawerProps) {
    const query = useQuery(eVoITransactionQuery(e_voi));

    return (
        <Drawer anchor="right" onClose={onClose} {...drawerProps}>
            <Stack sx={{ flexGrow: 1, width: '35rem' }}>
                <DialogTitle component={Stack} direction="row" gap={2} alignItems="center">
                    <IconButton edge="start" aria-label="close" onClick={onClose}>
                        <IconX />
                    </IconButton>

                    <Typography variant="h6">Verification of Identity</Typography>
                </DialogTitle>

                <DialogContent>
                    <Stack gap={3}>
                        <ReadonlyField
                            label="Given name(s)"
                            value={query.data?.input_given_names}
                        />
                        <ReadonlyField label="Family name" value={query.data?.input_family_name} />

                        <ReadonlyField label="Status" value={<VoIResults eVoI={e_voi} />} />
                    </Stack>
                </DialogContent>

                <DialogActions>
                    <IDVerseBadge />
                </DialogActions>
            </Stack>
        </Drawer>
    );
}

export default VerificationOfIdentityTool;
