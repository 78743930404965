import { useSearchParams } from 'react-router-dom';

function useSort(defaultSort: string) {
    const [searchParams] = useSearchParams();
    const sort = searchParams.get('sort')?.toString() || defaultSort;

    const sortColumn = sort.replace('-', '');
    const sortDirection: 'asc' | 'desc' = !sort.startsWith('-') ? 'asc' : 'desc';

    function sortLink(column: string) {
        const params = new URLSearchParams(searchParams);
        const sort = (sortColumn === column && sortDirection === 'asc' ? '-' : '') + column;
        if (sort === defaultSort) params.delete('sort');
        else params.set('sort', sort);
        return `?${params}`;
    }

    return { sort, sortColumn, sortDirection, sortLink };
}

export default useSort;
