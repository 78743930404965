import { Stack, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import {
    PaginatedGenericDocumentListRequestParams,
    getGenericDocuments,
} from '../../../api/genericDocument';
import { listSatementOfPositionTasksQuery } from '../../../api/statementOfPosition';
import DateTimeString from '../../../components/DateTimeString';
import Placeholder from '../../../components/Placeholder';
import QueryProgress from '../../../components/QueryProgress';
import SearchParamSelect, { SearchParamSelectOption } from '../../../components/SearchParamSelect';
import DocumentsTable from '../../../components/documentsTable/DocumentsTable';
import {
    DocumentTableRowCreatedAt,
    DocumentTableRowFile,
    DocumentTableRowUploadedByIndustry,
    DocumentTableRowUploadedByOrganisation,
    DocumentTableRowUploadedByUser,
} from '../../../components/documentsTable/DocumentsTableCells';
import PageLayout from '../PageLayout';
import { useWorkspace } from '../workspaceContext';

export default function CorrespondanceStatementOfPosition() {
    const [searchParams] = useSearchParams();

    const tab = searchParams.get('tab') || 'all';
    function tabLink(tab?: string) {
        const params = new URLSearchParams(searchParams);
        tab ? params.set('tab', tab) : params.delete('tab');
        return `?${params}`;
    }

    return (
        <PageLayout
            title={
                <Stack direction="row" gap={3} alignItems="flex-end">
                    Statement of Position
                    <Tabs value={tab} className="underlined" sx={{ pb: 1 }}>
                        <Tab
                            label={'Documents'}
                            value={'all'}
                            className="underlined"
                            component={RouterLink}
                            to={tabLink('all')}
                            state={{ keepPreviousData: 'false' }}
                        />
                        <Tab
                            label={'Shared off platform'}
                            value={'shared_off_platform'}
                            className="underlined"
                            component={RouterLink}
                            to={tabLink('shared_off_platform')}
                            state={{ keepPreviousData: 'false' }}
                        />
                    </Tabs>
                </Stack>
            }
        >
            {tab === 'all' && <StatementOfPostionDocumentsTable />}
            {tab === 'shared_off_platform' && <SharedOffPlatformTable />}
        </PageLayout>
    );
}

function StatementOfPostionDocumentsTable() {
    const [searchParams] = useSearchParams();
    const workspace = useWorkspace();

    const membershipParam = searchParams.get('membership') || undefined;
    const defaultSort = '-created_at';
    const sortParam = searchParams.get('sort')?.toString() || defaultSort;

    const queryOptions: PaginatedGenericDocumentListRequestParams = {
        membership: membershipParam,
        workspace: workspace.id,
        ordering: sortParam,
        //task_type: ['estate_finalisation'],
    };

    const query = useQuery({
        ...getGenericDocuments('statement_of_position', queryOptions),
        // Prevent the data in the table from disappearing when the query options change
        placeholderData: (previousData, previousQuery) => previousData,
    });
    const documents = query.data?.results || [];

    const memberFilterOptions: SearchParamSelectOption[] =
        query.data?.results
            .filter((result) => !!result.membership)
            .map((result) => ({
                id: result.membership || '',
                label:
                    result.uploaded_by.organisation_external_display_name ||
                    result.uploaded_by.organisation_name ||
                    '',
            }))
            .sort((org1, org2) => org1.label.localeCompare(org2.label))
            .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id)) ||
        [];

    return (
        <QueryProgress query={query}>
            <Stack gap={2}>
                <SearchParamSelect
                    sx={{ mt: -2 }}
                    parameter={'membership'}
                    options={memberFilterOptions}
                    placeholder="Filter by Participant"
                />
                <DocumentsTable
                    sort={sortParam}
                    documents={documents}
                    placeholder={
                        <Placeholder
                            title="No files yet"
                            description="Statement of Position files uploaded by Workspace Participants will appear here"
                        />
                    }
                    columns={[
                        {
                            id: 'uploadedByOrganisation',
                            label: 'Participant',
                            Component: DocumentTableRowUploadedByOrganisation,
                        },
                        {
                            id: 'uploadedByIndustry',
                            label: 'Sector',
                            Component: DocumentTableRowUploadedByIndustry,
                        },
                        {
                            id: 'file',
                            label: 'File',
                            Component: DocumentTableRowFile,
                        },
                        {
                            id: 'createdAt',
                            label: 'Uploaded at',
                            sortable: 'created_at',
                            Component: DocumentTableRowCreatedAt,
                        },
                        {
                            id: 'uploadedByUser',
                            label: 'Uploaded by',
                            sortable: 'uploaded_by',
                            Component: DocumentTableRowUploadedByUser,
                        },
                    ]}
                />
            </Stack>
        </QueryProgress>
    );
}

function SharedOffPlatformTable() {
    const workspace = useWorkspace();
    const query = useQuery(
        listSatementOfPositionTasksQuery({
            workspace: workspace.id,
            distribution_method: 'off_platform',
        })
    );

    const tasks = query.data?.results || [];

    return (
        <QueryProgress query={query}>
            <Stack gap={2}>
                {tasks.length === 0 ? (
                    <Placeholder
                        title="Nothing shared off platform yet"
                        description="Participants who have shared documents off-platform will appear here"
                    />
                ) : (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Participant</TableCell>
                                <TableCell>Sector</TableCell>
                                <TableCell>Marked by</TableCell>
                                <TableCell>Marked at</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tasks.map((task) => (
                                <TableRow key={task.id}>
                                    <TableCell>{task.participant_name}</TableCell>
                                    <TableCell>{task.participant_industry_code_label}</TableCell>
                                    <TableCell>
                                        {task.distribution_method_changed_by_name}
                                    </TableCell>
                                    <TableCell>
                                        {DateTimeString(task.distribution_method_changed_at)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </Stack>
        </QueryProgress>
    );
}
